import React, { useState, useContext, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import QRCode from "react-qr-code"
import Form from "react-bootstrap/Form"

import styles from "./QRCode.module.scss"
import Copy from "../../../assets/Copy.svg"
import { UserContext } from "../../context/User"
import { FiatSign, AssetNames } from "../../constants"

function QRCodeComponent(props: any): JSX.Element {
  const history = useHistory()
  const [showCopyText, setShowCopyText] = useState(false)
  const [btnSelected, setBtnSelected] = useState(0)
  const [qrCodeString, setQrCodeString] = useState("")
  const [qrFormatType, setQrFormatType] = useState(
    "QR Code contains wallet address"
  )
  const [allQRCodes, setAllQRCodes] = useState([
    {
      id: 1,
      qrTypeName: "Only wallet address",
      walletAddress: "0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec"
    },
    {
      id: 2,
      qrTypeName: "Wallet address and amount",
      walletAddress: "0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec"
    },
    {
      id: 3,
      qrTypeName: "Wallet address and value",
      walletAddress: "0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec"
    },
    {
      id: 4,
      qrTypeName: "Wallet address, asset and amount",
      walletAddress: "0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec"
    },
    {
      id: 5,
      qrTypeName: "Wallet address, asset and value",
      walletAddress: "0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec"
    },
    {
      id: 6,
      qrTypeName: "Wallet address and asset",
      walletAddress: "0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec"
    }
  ])
  // const [allQRCodes, setAllQRCodes] = useState([
  //   {
  //     id: 1,
  //     qrTypeName: "QR Code contains wallet address",
  //     walletAddress: "0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec"
  //   },
  //   {
  //     id: 2,
  //     qrTypeName: "QR Code contains wallet address and amount",
  //     walletAddress: "0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec"
  //   },
  //   {
  //     id: 3,
  //     qrTypeName: "QR Code contains wallet address and value",
  //     walletAddress: "0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec"
  //   },
  //   {
  //     id: 4,
  //     qrTypeName: "QR Code contains wallet address, asset and amount",
  //     walletAddress: "0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec"
  //   },
  //   {
  //     id: 5,
  //     qrTypeName: "QR Code contains wallet address, asset and value",
  //     walletAddress: "0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec"
  //   },
  //   {
  //     id: 6,
  //     qrTypeName: "QR Code contains wallet address and asset",
  //     walletAddress: "0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec"
  //   }
  // ])
  const [qrCounter, setqrCounter] = useState(10)
  const [orderCount, setOrderCount] = useState(1200)
  const { user } = useContext(UserContext)

  const qrtimer = () => setqrCounter(qrCounter - 1)
  const ordertimer = () => setOrderCount(orderCount - 60)
  const orderCurrency = `${props.orderdata.currency}` as string
  const orderFiatCurrency = `${props.orderdata.fiatCurrency}` as string

  useEffect(() => {
    if (qrCounter <= 0) {
      setqrCounter(10)
      props.invoke()
      return
    }
    const id = setInterval(qrtimer, 1000)
    return () => clearInterval(id)
  }, [qrCounter])

  useEffect(() => {
    if (orderCount <= 0) {
      setOrderCount(1200)
      props.navigateback()
      return
    }
    const orderid = setInterval(ordertimer, 60000)
    return () => clearInterval(orderid)
  }, [orderCount])

  useEffect(() => {
    console.log(props.orderdata)
    if (props.orderdata.walletAddress) {
      setQrCodeString(`${props.orderdata.walletAddress}`)
      qrFormats(props.orderdata.walletAddress)
    }
  }, [props.orderdata])

  const copyAddress = (address: string) => {
    navigator.clipboard.writeText(address)
    setShowCopyText(true)
    setTimeout(() => {
      setShowCopyText(false)
    }, 2000)
  }

  const qrFormats = (type: string) => {
    // const recievedCurrencyParam =
    //   AssetNames[orderCurrency as keyof typeof AssetNames]
    const recievedCurrencyParam = orderCurrency
    if (recievedCurrencyParam) {
      const allqrs = [...allQRCodes]
      allqrs[0].walletAddress = `${props.orderdata.walletAddress}`
      allqrs[1].walletAddress = `${props.orderdata.walletAddress}?amount=${props.orderdata.amount}`
      allqrs[2].walletAddress = `${props.orderdata.walletAddress}?value=${props.orderdata.amount}`
      allqrs[3].walletAddress = `${recievedCurrencyParam}:${props.orderdata.walletAddress}?amount=${props.orderdata.amount}`
      allqrs[4].walletAddress = `${recievedCurrencyParam}:${props.orderdata.walletAddress}?value=${props.orderdata.amount}`
      allqrs[5].walletAddress = `${recievedCurrencyParam}:${props.orderdata.walletAddress}`
      setAllQRCodes(allqrs)
    }
  }

  return (
    <div className="row mt-4">
      <div className="col-md-4">
        <div className={styles.row}>
          <div className={styles.itemTitle}>
            Total Amount
            <div className={styles.totalAmount}>
              {props.orderdata.amount} {props.orderdata.currency}
            </div>
          </div>
          <div className={styles.itemTitle}>
            Total Fiat
            <div className={styles.totalAmount}>
              {props.orderdata.fiatAmount} {props.orderdata.fiatCurrency}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <div className={styles.row}></div>
      </div>
      <div className="col-md-2">
        <div className={styles.row}></div>
      </div>
      <div className="col-md-4">
        <div>
          <Form.Group className="mb-3 col-8" style={{ margin: "0 auto" }}>
            <Form.Label className={styles.itemTitle}>Wallet Address</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                placeholder="Wallet Address"
                disabled
                className="col-md-6"
                value={props.orderdata.walletAddress || ""}
                // value={"0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec"}
              />
              <img
                src={Copy}
                onClick={() => copyAddress(props.orderdata?.walletAddress)}
                // onClick={() =>
                //   copyAddress("0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec")
                // }
                style={{
                  padding: "10px",
                  background: "#e9ecef",
                  cursor: "pointer"
                }}
              />
            </div>
            {showCopyText ? (
              <h6 style={{ color: "red" }} className="mt-2">
                Copied
              </h6>
            ) : null}
          </Form.Group>
        </div>
      </div>
      <div className="col-md-12 mt-2">
        <div className={styles.itemTitle} style={{ textAlign: "center" }}>
          <span>Scan QR Code to make payment</span>
          <h4
            style={{ padding: 0, margin: 0, color: "black" }}
            className={`mt-2 ${styles.itemTitle}`}
          >
            {qrCounter}
          </h4>
          <div className={`mt-2`}>
            <p className={styles.textDispaly}>
              You have 60 seconds to complete the transaction with the currently
              displayed QR code
            </p>
          </div>
        </div>
        <div className="row mt-4 mb-3">
          {allQRCodes.map((item) => {
            return (
              <div
                className="col-md-2"
                key={item.id}
                style={{ textAlign: "center" }}
              >
                <QRCode value={item.walletAddress} size={160} />
                <p
                  style={{ padding: 0, margin: 0 }}
                  className={`mt-2 ${styles.textDispaly}`}
                >
                  {item.qrTypeName}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default QRCodeComponent
