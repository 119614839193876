import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import axios from "axios"

import Icon from "../../icons"
import successIcon from "../../../assets/transactions/success.png"
import underpaidIcon from "../../../assets/transactions/underpaid.png"
import overpaidIcon from "../../../assets/transactions/overpaid.png"
import failedIcon from "../../../assets/transactions/failed.png"
import inprogressIcon from "../../../assets/transactions/inprogress.png"
import Layout from "../../ui/Layout/Layout"
import styles from "./SuccessNoAuth.module.scss"
import stylesQR from "../QRCode/QRCode.module.scss"

const REDIRECT_TIMEOUT = 100 // in seconds

interface OrderObject {
  [key: string]: any
}

const SuccessNoAuth: React.FC = (props: any) => {
  const [orderStatus, setOrderStatus] = useState("")
  const [orderDetails, setOrderDetails] = useState<OrderObject>({})
  const { search, state } = useLocation()
  const redirect = new URLSearchParams(search).get("redirect") || ""
  const orderId = new URLSearchParams(search).get("order_id") || ""

  useEffect(() => {
    if (redirect) {
      // console.log(redirect)
      const timer = setTimeout(function () {
        window.location.replace(redirect)
      }, REDIRECT_TIMEOUT * 1000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [search])

  useEffect(() => {
    if (orderId) {
      getOrderDetails(orderId)
    }
  }, [orderId])

  function getOrderDetails(id: string) {
    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_API_URL}api/v1/merchant/orderRefresh/${id}`
      )
      .then((res) => {
        // console.log(res.data)
        if (res.data) {
          setOrderStatus(res.data.orderStatus)
          setOrderDetails(res.data)
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message)
        } else if (error.request) {
          console.log(error.request)
        } else {
          console.log("Error", error.message)
        }
      })
  }

  const checkIcon = (iconName: string) => {
    switch (iconName) {
      case "IN_PROGRESS":
        return (
          <img
            src={inprogressIcon}
            alt={"In Progress"}
            width="50px"
            height="50px"
          />
        )
        break
      case "SUCCESSFUL":
        return (
          <img src={successIcon} alt={"success"} width="50px" height="50px" />
        )
        break
      case "FAILED":
        return (
          <img src={failedIcon} alt={"Failed"} width="50px" height="50px" />
        )
        break
      case "OVERPAID":
        return (
          <img src={overpaidIcon} alt={"Overpaid"} width="50px" height="50px" />
        )
        break
      case "UNDERPAID":
        return (
          <img
            src={underpaidIcon}
            alt={"Underpaid"}
            width="50px"
            height="50px"
          />
        )
        break
      default:
        return null
        break
    }
  }

  return (
    <Layout
      buttonText="Go Back"
      onClick={() => window.location.replace(redirect)}
    >
      <div className="row" style={{ minWidth: "800px" }}>
        <div className="col-md-12 mt-4" style={{ textAlign: "center" }}>
          {checkIcon(orderStatus)}
          <h4 className="mt-2">{orderStatus}</h4>
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-8" style={{ textAlign: "center" }}>
          <div className={stylesQR.row}>
            <div className={stylesQR.itemTitle}>
              Digital Amount
              <div className={stylesQR.totalAmount}>
                {orderDetails && orderDetails.amount}{" "}
                {orderDetails && orderDetails.currency}
              </div>
            </div>
            <div className={stylesQR.itemTitle}>
              Fiat Amount
              <div className={stylesQR.totalAmount}>
                {orderDetails && orderDetails.fiatAmount}{" "}
                {orderDetails && orderDetails.fiatCurrency}
              </div>
            </div>
            <div className={stylesQR.itemTitle}>
              Digital Recieved Amount
              <div className={stylesQR.totalAmount}>
                <div className={stylesQR.totalAmount}>
                  {orderDetails && orderDetails.totalDigitalCurrencyReceived}{" "}
                  {orderDetails && orderDetails.currency}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-4" style={{ textAlign: "center" }}>
          <div className={stylesQR.itemTitle}>
            Your payment has been completed successfully! In a few seconds you
            will be redirected...
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SuccessNoAuth
