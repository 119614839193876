import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

import Layout from "../../ui/Layout/Layout"
import QRCodeComponent from "./QRCode"
import routes from "../../routes"

function RenderQR(props: any): JSX.Element {
  const { search } = useLocation()
  const [windowSize, setwindowSize] = useState(800)
  const history = useHistory()
  const redirect = new URLSearchParams(location.search).get("redirect") || ""

  useEffect(() => {
    if (props.orderdata) {
      if (
        props.orderdata.orderStatus == "SUCCESSFUL" ||
        props.orderdata.orderStatus == "UNDERPAID" ||
        props.orderdata.orderStatus == "OVERPAID"
      ) {
        history.push({ pathname: routes.succesnoauth, search })
      }
    }
  }, [props.orderdata])

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window
    // console.log(width)
    setwindowSize(width - 200)
  }, [])

  return (
    <Layout
      buttonText="Cancel Order"
      onClick={() => window.location.replace(redirect)}
    >
      <div className="row mt-1" style={{ minWidth: `${windowSize}px` }}>
        <QRCodeComponent {...props} />
      </div>
    </Layout>
  )
}

export default RenderQR
