import React, { useContext, useState, useEffect } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import axios from "axios"

import { Form, Summary, Success } from "./components"
import SuccessNoAuth from "./components/screens/SuccessNoAuth/SuccessNoAuth"
import RenderQR from "./components/screens/QRCode/RenderQR"
import PrivateRoute from "./components/PrivateRoute"
import PublicRoute from "./components/PublicRoute"
import routes from "./components/routes"
import Loading from "./components/ui/Loading/Loading"
import styles from "./App.module.scss"
import logo from "./assets/WadzPay-Logo.png"
import { UserContext, UserContextProvider } from "./components/context/User"
import Layout from "./components/ui/Layout/Layout"
import { BlockedCountries } from "./components/constants"

const Routes: React.FC = () => {
  const { user, isLoading } = useContext(UserContext)

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <img src={logo} alt="Logo" width={300} className={styles.logo} />
        <Loading size={40} />
      </div>
    )
  }

  return (
    <Router>
      <Switch>
        <Route exact path={routes.signIn}>
          <Form />
        </Route>
        <PrivateRoute path={routes.summary} isAuthenticated={!!user}>
          <Summary />
        </PrivateRoute>
        <PrivateRoute path={routes.success} isAuthenticated={!!user}>
          <Success />
        </PrivateRoute>
      </Switch>
    </Router>
  )
}

const queryClient = new QueryClient()

interface OrderObject {
  [key: string]: any
}

const App: React.FC = () => {
  const [details, setDetails] = useState("")
  const [orderDetails, setOrderDetails] = useState<OrderObject>({})
  const [orderDetailsResText, setOrderDetailsResText] = useState<string>("test")
  const redirect = new URLSearchParams(location.search).get("redirect") || ""
  const orderId = new URLSearchParams(location.search).get("order_id") || ""

  const NoAuthRoutes: React.FC = () => {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <RenderQR
              orderdata={orderDetails}
              invoke={() => getOrderDetails()}
              navigateback={() => window.location.replace(redirect)}
            />
          </Route>
          <PublicRoute path={routes.succesnoauth}>
            <SuccessNoAuth />
          </PublicRoute>
        </Switch>
      </Router>
    )
  }

  useEffect(() => {
    checkUserLocation()
  }, [])

  useEffect(() => {
    if (orderId) {
      getOrderDetails()
    }
  }, [orderId])

  const obj = {
    uuid: "bb248e6c-d992-47dd-887b-c83f73e11c88",
    amount: "0.005",
    currency: "BTC",
    type: "ORDER",
    fiatAmount: "2.8888",
    fiatCurrency: "USD",
    target: {
      name: "NewMerchantAbhinav",
      countryOfRegistration: "IN",
      registrationCode: "123456",
      primaryContactFullName: "Dubai Duty Free",
      primaryContactEmail: "abhinav.maurya@wadzpay.com",
      primaryContactPhoneNumber: "+919319017813",
      companyType: "Software",
      industryType: "ACCOUNTING",
      merchantId: "merchantID123",
      merchantPoses: [
        { posId: "0", posName: "Beer" },
        { posId: "1", posName: "pos0ne" }
      ]
    },
    targetEmail: "sample@gmail.com",
    createdAt: "2022-06-22T14:23:15.523338Z",
    externalOrderId: "1234",
    description: "Testing WTK",
    isCancelled: false,
    isFailed: false,
    isThirdParty: true,
    walletAddress: "0x1039347cbec7bba82d77c90eb6ffb6c12f7e63ec",
    orderStatus: "IN_PROGRESS",
    status: "EXPIRED"
  }

  // useEffect(() => {
  //   console.log(orderDetails)
  // }, [orderDetails])

  function getOrderDetails() {
    axios
      .get(
        // `${process.env.REACT_APP_PUBLIC_API_URL}v1/merchant/order/${orderId}`
        `${process.env.REACT_APP_PUBLIC_API_URL}api/v1/merchant/orderRefresh/${orderId}`
      )
      .then((res) => {
        // console.log(res)
        setOrderDetails(res.data)
        // setOrderDetails(obj)
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message)
          setOrderDetailsResText(error.response.data.message)
          // setOrderDetails(obj)
          // setTimeout(() => {
          //   const objNew = { ...obj }
          //   objNew.orderStatus = "SUCCESS"
          //   setOrderDetails(objNew)
          // }, 10000)
        } else if (error.request) {
          // console.log(error.request)
          setOrderDetailsResText("No response recieved...")
        } else {
          // console.log("Error", error.message)
          setOrderDetailsResText("Soemthing went worng. Try Again...")
        }
      })
  }

  function checkUserLocation() {
    fetch("https://geolocation-db.com/json/")
      .then((response) => {
        if (!response.ok) {
          console.log(response.statusText)
        }
        return response.json()
      })
      .then((responseObj) => setDetails(responseObj.country_name))
      .catch((err) => {
        console.log(err)
      })
  }

  if (details === "") {
    return <Layout title="Please wait..." />
  }
  if (details === BlockedCountries.SINGAPORE) {
    return (
      <Layout
        title={"Sorry! We are not currently available in " + details + "."}
        buttonText="Go Back"
        onClick={() => window.location.replace(redirect)}
      />
    )
  }
  if (orderDetails && orderDetails.isThirdParty == true) {
    return <NoAuthRoutes />
  }

  if ((orderDetails && orderDetails.isThirdParty == false) || orderId == "") {
    return (
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <Routes />
        </UserContextProvider>
      </QueryClientProvider>
    )
  }

  // if (Object.keys(orderDetails).length == 0) {
  //   return <Layout title={orderDetailsResText} />
  // }
  if (Object.keys(orderDetails).length == 0) {
    return (
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <Routes />
        </UserContextProvider>
      </QueryClientProvider>
    )
  }

  return <Layout title={orderDetailsResText} />
}

export default App
